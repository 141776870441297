import React, { Component } from "react";

class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placeholder: this.props.placeholder,
      type: this.props.type,
      id: this.props.id,
      active: false,
    };
  }
  handleBlur = (value) => {
    if (/^[\s]*$/.test(value.toString())) {
      this.setState({ active: false });
    }
  };

  render() {
    const { placeholder, id, type, active } = this.state;
    return (
      <div className={`inp-group ${active ? "active" : ""}`}>
        <label htmlFor={id}>
          {placeholder}
          {this.props.required ? "*" : ""}
        </label>
        <input
          required={this.props.required}
          onBlur={(e) => this.handleBlur(e.target.value)}
          onFocus={() => this.setState({ active: true })}
          id={id}
          name={id}
          type={type}
        />
      </div>
    );
  }
}

export default Input;
