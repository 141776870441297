import React from "react";
import { Link } from "gatsby";

const Breadcrumbs = ({ data, lang }) => {
  return (
    <div className="main-wrapper-md">
      <ul
        itemScope
        itemType="https://schema.org/BreadcrumbList"
        className="breadcrumbs-list"
      >
        <li
          itemScope
          itemType="https://schema.org/ListItem"
          itemProp="itemListElement"
          className={`breadcrumb-item`}
        >
          {lang === "en" && <Link to="/en">Home</Link>}
          {lang === "pl" && <Link to="/pl">Strona główna</Link>}
          <meta itemProp="position" content="1" />
        </li>
        {data &&
          data.map(({ name, href }, index) => {
            if (data.length - 1 === index) {
              return (
                <li
                  itemScope
                  itemType="https://schema.org/ListItem"
                  itemProp="itemListElement"
                  key={name}
                  className={`breadcrumb-item`}
                >
                  <span itemProp="name">{name}</span>
                  <meta itemProp="position" content={index + 2} />
                </li>
              );
            } else {
              return (
                <li
                  itemScope
                  itemType="https://schema.org/ListItem"
                  itemProp="itemListElement"
                  key={name}
                  className={`breadcrumb-item`}
                >
                  <Link itemProp="name" to={`/${href}`}>
                    {name}
                  </Link>
                  <meta itemProp="position" content={index + 2} />
                </li>
              );
            }
          })}
      </ul>
    </div>
  );
};

export default Breadcrumbs;
