import React from "react";

const Employee = ({ position, name, tel, mail, image }) => {
  return (
    <div className="emploee-box">
      {image && (
        <div className="box-image">
          <img src={image} alt={name} />
        </div>
      )}
      <div className="box-text">
        {position && <p className="employee-position">{position}</p>}
        <p className="employee-name">{name}</p>
        {tel && (
          <p>
            tel: <a href={`tel:${tel.split(" ").join("")}`}>{tel}</a>
          </p>
        )}
        {mail && (
          <p>
            e-mail: <a href={`mailto:${mail}`}>{mail}</a>
          </p>
        )}
      </div>
    </div>
  );
};

export default Employee;
