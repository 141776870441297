import React from 'react'


const Check = ({id, label}) => {
    return ( 
        <div className="check-group">
            <input type="checkbox" name={id} id={id} required/>
            <label htmlFor={id}>{label}</label>
        </div>
     );
}
 
export default Check;