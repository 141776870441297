import React, { Component } from "react";

class Textarea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placeholder: this.props.placeholder,
      id: this.props.id,
    };
  }

  render() {
    const { placeholder, id } = this.state;
    return (
      <div className={`textarea-group`}>
        <textarea
          required={this.props.required}
          name={id}
          id={id}
          placeholder={`${placeholder}${this.props.required ? "*" : ""}`}
        />
      </div>
    );
  }
}

export default Textarea;
